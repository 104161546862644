import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { SstStateService } from '../../services/sst-state.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { GSearchService } from '../../services/g-search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileSstStateService } from '../../services/mobile-sst-state.service';
import { DeviceService } from '../../services/device.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-location-adv-search',
  templateUrl: './location-adv-search.component.html',
  styleUrls: ['./location-adv-search.component.scss'],
})
export class LocationAdvSearchComponent
  implements OnChanges, AfterContentInit, AfterViewInit
{
  @ViewChild('ngSelect') ngSelect!: NgSelectComponent;
  @Output() onLocationUpdate = new EventEmitter();
  @Output() onclose = new EventEmitter();
  @Output() onEnter = new EventEmitter();
  @Input() currentValue: any = '';
  @Input() inputError: any = {};
  @Input() focusOnClick: boolean = false;
  @Input() isCurrLocation: boolean = false;
  @Input() shouldClearInput: boolean = false;
  @Input() autoFocus: boolean = false;

  suggestions: any[] = [];
  typeaheadLoading = false;
  typeahead$: Subject<string> = new Subject<string>();

  results$: Observable<any[]> = new Observable<any[]>();
  showDropOptions: boolean = false;
  keywordTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  recentList: any = [];

  loaded: boolean = false;

  _dropOptions!: Subscription;

  _focusEvent!: Subscription;

  constructor(
    private sst: SstStateService,
    private gService: GSearchService,
    public sstState: SstStateService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private mobileSst: MobileSstStateService,
    private device: DeviceService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this._dropOptions = this.sst.showDropOptions.subscribe((res) => {
      this.showDropOptions = res.location;
    });

    this._focusEvent = this.sst.focusSearchFieldEvent.subscribe((res) => {
      if (res.location && this.focusOnClick) {
        setTimeout(() => {
          this.ngSelect.focus();
        }, 500);
      }
    });

    const _locationError = this.sst.locationError.subscribe((hasError) => {
      this.inputError['address'] = hasError;
    });

    const _handleSelectedItem: Subscription =
      this.sst.onSelectedItem$.subscribe(($event) => {
        this.selectedItem($event);
      });

    // const _route = this.route.queryParams.subscribe((params) => {
    //   const address = params['address'] as string;
    //   if(address && this.ngSelect && typeof address == 'string') {
    //     setTimeout(() => {
    //     this.ngSelect.searchInput.nativeElement.value = address.replace(/-/g, ' ');
    // console.log('location===', this.ngSelect.searchInput.nativeElement.value)

    //     }, 200);
    //   }
    //   const zip = params['zip'] as string;
    //   if (zip && typeof zip == 'string') {
    //     setTimeout(() => {

    //     this.ngSelect.searchInput.nativeElement.value = zip.replace(/-/g, ' ');
    // console.log('location===', this.ngSelect.searchInput.nativeElement.value)

    //     }, 200);

    //   }

    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.setInputValue();
    }, 200);
  }

  setAutoFocus() {
    setTimeout(() => {
      if (this.autoFocus) {
        this.ngSelect.focus();
        this.ngSelect.searchInput.nativeElement.select();
        this.ngSelect.searchInput.nativeElement.focus();
      }
    }, 500);
  }

  initMobileSst() {
    if (this.device.width > 428) {
      return;
    }

    const _location = this.mobileSst.location.subscribe((res) => {
      if (res == null) {
        this.ngSelect.searchInput.nativeElement.value = '';
        return;
      }
      this.ngSelect.searchInput.nativeElement.value = res.address || res.zip;
    });
  }
  setInputValue() {
    const url = this.route.queryParams.subscribe((res) => {
      if (this.router.url.includes('school')) {
        return;
      }
      const address = res['address'] || undefined;
      const zip = res['zip'] || undefined;
      if (!address && !zip) {
        this.currentValue['address'] = null;
        this.currentValue['location'] = null;
        this.ngSelect.searchInput.nativeElement.value = '';
      } else if (typeof address == 'string') {
        this.ngSelect.searchInput.nativeElement.value = address;
      } else if (typeof zip == 'string') {
        this.ngSelect.searchInput.nativeElement.value = zip;
      } else if (this.currentValue['location']?.name) {
        this.ngSelect.searchInput.nativeElement.value =
          this.currentValue['location']?.name;
      } else if (typeof this.currentValue['location'] === 'string') {
        this.ngSelect.searchInput.nativeElement.value =
          this.currentValue['location'];
      } else if (
        this.currentValue['address']?.name &&
        this.currentValue['address']?.source !== 'zip'
      ) {
        this.ngSelect.searchInput.nativeElement.value =
          this.currentValue['address']?.name;
      } else if (typeof this.currentValue['address'] === 'string') {
        this.ngSelect.searchInput.nativeElement.value =
          this.currentValue['address'];
      } else if (typeof this.currentValue === 'string') {
        this.ngSelect.searchInput.nativeElement.value = this.currentValue;
      } else {
        this.ngSelect.searchInput.nativeElement.value = '';
      }
      if (this.isCurrLocation) {
        // setTimeout(() => {
        //   this.ngSelect.searchInput.nativeElement.value = 'Current Location';
        // }, 300);
      }
      if (this.shouldClearInput) {
        this.ngSelect.searchInput.nativeElement.value = '';
      }
      this.ngSelect.searchInput.nativeElement.value =
        this.ngSelect.searchInput.nativeElement.value.replace(/-/g, ' ');
    });
  }
  ngOnInit(): void {
    this.results$ = this.typeahead$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap((term) => {
        this.typeaheadLoading = true;
        // this.searchForm.controls['keyword'].setValue(term);
      }),
      switchMap((term) =>
        this.sst.actionSearch(term ?? '').pipe(
          catchError(() => of([])), // empty list on error
          tap((res) => {
            this.typeaheadLoading = false;
          })
        )
      )
    );

    this.results$.subscribe((items) => {
      this.suggestions = this.getSuggestions(items);
    });

    document.body.addEventListener('click', () => {
      this.sstState.updateDropOptions('location', false);
      this.showDropOptions = false;
    });
  }

  ngAfterViewInit(): void {
    this.recentList = this.sst.getRecentSearch().map((entry: any) => {
      if (entry.key == 'zip') {
        entry['class'] = 'fa fa-map-pin';
      }
      if (entry.key == 'address') {
        entry['class'] = 'fa fa-location-dot';
      }
      if (entry.key == 'keyword') {
        entry['class'] = '';
      }
      return entry;
    });

    this.recentList = this.filterRecentList(this.recentList);
    // this.ngSelect.searchInput.nativeElement.placeholder =
    //   '"Santa Monica", "1250 Midvale"';
    const lastLocation: string =
      this.currentValue?.name || this.currentValue || '';
    if (lastLocation.length > 0) {
      let searchValue = lastLocation.replace(/-/g, ' ');

      if (this.isZipCode(lastLocation)) {
        searchValue = searchValue.substring(0, 5);
      }
      this.ngSelect.searchInput.nativeElement.value = searchValue;
    }

    this.renderer.listen('body', 'click', (ev) => {
      this.showDropOptions = false;
    });

    this.setAutoFocus();
    // this.ngSelect.focus();
    // this.ngSelect.searchInput.nativeElement.select();
    // this.ngSelect.searchInput.nativeElement.focus();
  }

  isZipCode(address: string): boolean {
    const zipRegex = /^\d{5}(?:-\d{4})?$/; // Regular expression to match a 5-digit zip code pattern

    if (zipRegex.test(address)) {
      return true; // Return the first 5 characters as the zip code
    }

    return false;
  }

  ngAfterContentInit(): void {
    this.initMobileSst();
    // setTimeout(() => {
    //   this.ngSelect.blur();
    //   this.showDropOptions = false;
    // }, 250);
  }

  filterRecentList(list: any[]) {
    return list.filter((item) => item.key == 'address' || item.key == 'zip');
  }

  onInputSearch(ev: any) {
    this.keywordTerm.next(ev.term);
    if (ev.term.length === 0 || !ev) {
      this.showDropOptions = true;
      return;
    }
    // hide first dropdown
    this.showDropOptions = false;
  }

  shouldLimitList = {
    zip: true,
    address: true,
    school: true,
    location: true,
  };

  getSuggestions(items: any) {
    if (!items || items.length == 0) {
      return [];
    }

    const order = ['location', 'address', 'zip'];
    const suggestions: any[] = [];

    for (const key of order) {
      const shouldLimit = this.shouldLimitList[key];
      if (shouldLimit) {
        const itemsOfKey = items.filter((item) => item.source === key);
        const limit = itemsOfKey.slice(0, 5) as any;
        suggestions.push(...limit);
      }
    }

    return suggestions;
  }

  addInput(input) {
    this.selectedItem({
      item: {
        id: input.value.replace(/ /g, '-').replace(/,/g, ''),
        name: input.value,
        source: input.key,
      },
    } as any);
  }

  selectedItem($event: any) {
    this.sst.updateLocation($event);
    this.sst.updateLocationInfo($event);
    this.mobileSst.location.next($event);

    let qParams: any = {};
    if (!this.ngSelect) {
      return;
    }
    this.ngSelect.searchTerm = $event.name;
    //if (this.actionForm.controls['grade'].value)
    //this.searchSettings. = this.actionForm.controls['grade'].value;
  }

  handleEnter() {
    if (this.suggestions.length == 0) {
      this.toastr.error('Please select a valid location.');
      return;
    }
    this.selectedItem(this.suggestions[0]);
  }

  searchKeys() {
    // this.sst.isCurrentLocation$.next(false);
    // const searchTerm = this.ngSelect.searchTerm;
    // this.sst.searchPageSettings.searchType = 'home';
    // if (searchTerm?.length == 0 || !!!searchTerm) {
    //   this.inputError = true;
    //   this.toastr.error(`Please include a keyword or location.`);
    //   return;
    // }
    // let keySearch: any = { tags: this.searchForm.controls['filter'].value };
    // if (this.selectedGrades.length > 0)
    //   keySearch['grade'] = this.selectedGrades;
    // this.inputError = false;
    // this.route.navigate([`search`], {
    //   queryParams: {
    //     tags: searchTerm,
    //     grade: this.selectedGrades,
    //   },
    // });
  }

  handleLocationChange(ev: any) {
    this.ngSelect.searchInput.nativeElement.value = ev.name || '';
    this.onLocationUpdate.emit(ev);
  }

  handleClose() {
    this.onclose.emit(); // Closes mobile modal
  }

  clearField() {
    this.ngSelect.searchTerm = '';
    this.ngSelect.searchInput.nativeElement.value = '';
    this.sst.isCurrentLocation$.next(false);
    this.handleLocationChange('');
    this.sst.searchPageSettings.address = '';
    this.sst.searchPageSettings.zip = '';
  }
}
