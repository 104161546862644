/// <reference types="@types/google.maps" />
import { Injectable, NgZone } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { SchoolService } from '../api/facilities';
import { HttpClient } from '@angular/common/http';
import { TranslatorService } from './translator.service';

@Injectable({
  providedIn: 'root',
})
export class GSearchService {
  $window = window;

  constructor(
    private schoolService: SchoolService,
    private zone: NgZone,
    private httpClient: HttpClient
  ) {}

  searchByPlaceId(
    placeId: string
  ): Observable<google.maps.places.PlaceResult | null> {
    const self = this;
    return new Observable((observer) => {
      // const map = new google.maps.Map(document.createElement('div'));
      // const service = new google.maps.places.PlacesService(map);
      // service.getDetails({ placeId: placeId }, function (result, status) {
      placeId = placeId.replace(/\\/g, ' ');
      var arcGisCall = `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?SingleLine=${placeId}&category=&searchExtent=-118.951721,32.75004,-117.646374,34.823302&outFields=*&forStorage=false&f=pjson`;
      self.httpClient.get(arcGisCall).subscribe((response: any) => {
        self.zone.run(() => {
          if (response.candidates.length > 0) {
            var result = {} as any;
            result.arcGis = response.candidates[0];
            observer.next(result);
            observer.complete();
          } else {
            observer.next([] as any);
            observer.complete();
          }
        });
      });
    });
  }
  searchByPlaceZip(placeId: string): Observable<any | null> {
    return this.searchByPlaceId(placeId);
    // const self = this;
    // return new Observable((observer) => {
    //   var arcGisCall = `https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?category=Point,Address,Postal&f=json&location=-118.3445351,34.047878&distance=38990&token=AAPK117d4867db4a49bdbb030ed88bf8b2f0mbPwMjKRXO0RiQyXlzmvgZhUXEBxQrkPLV9A2wWAkkqhgbBpGUN9PFierMYxg6Tu&searchExtent=-118.758372449,+33.5762743154,+-116.7493195729,+34.4917422117&sourceCountry=USA&text=${placeId}`;
    //   self.httpClient.get(arcGisCall).subscribe((response: any) => {
    //     self.zone.run(() => {
    //       if (response.suggestions.length > 0) {
    //         //   result!['arcGis'] = response.candidates[0] as any;
    //         observer.next(response.suggestions);
    //         observer.complete();
    //       } else {
    //         observer.next([] as any);
    //         observer.complete();
    //       }
    //     });
    //   });

    // const self = this;
    // const map = new google.maps.Map(document.createElement('div'));
    // const service = new google.maps.places.PlacesService(map);
    // service.findPlaceFromQuery(
    //   {
    //     query: `${placeId}, Los Angeles, CA, USA`,
    //     fields: ['ALL'],
    //   },
    //   function (result, status) {
    //     self.zone.run(() => {
    //       if (status === google.maps.places.PlacesServiceStatus.OK) {
    //         observer.next(result);
    //         observer.complete();
    //       } else {
    //         observer.next([]);
    //         observer.complete();
    //       }
    //     });
    //   }
    // );
    // });
  }

  getZipPrediction(term: string) {
    //return this.getAddressPrediction(term);
    let esRequest = {
      query: {
        query_string: {
          default_field: 'zip',
          query: `${term}*`,
        },
      },
    };
    return this.schoolService
      .v1SchoolIndexSearchPost('lausd_zip', esRequest)
      .pipe(
        switchMap((rs) =>
          rs.hits.hits.map((item: any) => {
            item._maxScore = rs.hits.max_score;
            return rs;
          })
        )
      );
  }
  getAddressPrediction(term: string): Observable<any | null> {
    const self = this;
    return new Observable((observer) => {
      //let reply = new Observable<any[]>();
      const arcGisToken =
        'AAPKd1b71280927944faa2145cd8e9225a05QlhiSe5rs15mld9OERRg-_KBfc5BnWTdEZG1Kgworj3bT9jD8mmbJwWt18tNP_Eg';
      var arcGisCall = `https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?category=Point,Address,City&f=json&location=-118.3445351,34.047878&distance=38990&token=${arcGisToken}&searchExtent=-118.951721,32.75004,-117.646374,34.823302&sourceCountry=USA&text=${term}`;
      self.httpClient.get(arcGisCall).subscribe((response: any) => {
        self.zone.run(() => {
          if (response.suggestions.length > 0) {
            observer.next(response.suggestions);
            observer.complete();
          } else {
            observer.next([] as any);
            observer.complete();
          }
        });
      });
      // const service = new google.maps.places.AutocompleteService();

      // const losAngelesCounty = { lat: 34.3500401, lng: -118.3924851 };
      // const losAngelesCountyBounds = {
      //   north: losAngelesCounty.lat + 0.55,
      //   south: losAngelesCounty.lat - 0.55,
      //   west: losAngelesCounty.lng + 0.55,
      //   east: losAngelesCounty.lng - 0.55,
      // };

      // service.getPlacePredictions(
      //   {
      //     input: `${term}, Los Angeles County`,
      //     types: ['street_address', 'premise', 'route','locality'],
      //     componentRestrictions: { country: ['us','ca'] },
      //     location: new google.maps.LatLng({ lat: 34.35, lng: -118.39 }, true),
      //     radius: 68000,
      //   },
      //   function (predictions, status) {
      //     predictions = predictions?.filter(p=>p.description.indexOf('Los Angeles County,') > 0)!
      //     predictions?.forEach((p)=>{
      //       p.description = `${p.structured_formatting.main_text}, ${p.structured_formatting.secondary_text}`
      //     })
      //     self.zone.run(() => {
      //       if (
      //         status != google.maps.places.PlacesServiceStatus.OK ||
      //         !predictions
      //       ) {
      //         // alert(status);
      //         observer.next([]);
      //         observer.complete();
      //       } else {
      //         observer.next(predictions);
      //         observer.complete();
      //       }
      //     });
      //   }
      // );
    });
  }

  searchByLatLong(lat: number, lng: number): Observable<string> {
    // const geocoder = new google.maps.Geocoder();
    // const latlng = new google.maps.LatLng(lat, lng);

    // return new Observable((observer) => {
    //   geocoder.geocode({ location: latlng }, (results: any, status) => {
    //     if (status === google.maps.GeocoderStatus.OK) {
    //       if (results[0]) {
    //         observer.next(results[0].formatted_address);
    //         observer.complete();
    //       } else {
    //         observer.error('No results found');
    //       }
    //     } else {
    //       observer.error(status);
    //     }
    //   });
    // });
    const self = this;
    return new Observable((observer) => {
      //let reply = new Observable<any[]>();
      const arcGisToken =
        'AAPKd1b71280927944faa2145cd8e9225a05QlhiSe5rs15mld9OERRg-_KBfc5BnWTdEZG1Kgworj3bT9jD8mmbJwWt18tNP_Eg';
      var arcGisCall = `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=json&langCode=en&featureTypes=&location=${lng},${lat}&distance=500&outSR=4326&forStorage=false&outFields=Place_addr&token=${arcGisToken}`;
      self.httpClient.get(arcGisCall).subscribe((response: any) => {
        self.zone.run(() => {
          if (response) {
            console.log('res',response.address)
            const address = this.cleanAndConcatenateAddress(response.address);
            observer.next(address);
            observer.complete();
          } else {
            observer.error('No results found');
          }
        });
      });
    });
  }

  cleanAndConcatenateAddress(geocodingResponse) {
    // Extract relevant fields from the response
    const streetAddress = geocodingResponse.Address || '';
    const city = geocodingResponse.City || '';
    const region = geocodingResponse.Region || '';
    const postalCode = geocodingResponse.Postal || '';
    const countryCode = geocodingResponse.CountryCode || '';
  
    // Regular expression pattern to match apartment or suite numbers in various formats
    const aptSuitePattern = /(\b(?:apt|apartment|suite|ste|#)\s*[\w\d-]+|#\s*[\w\d-]+\b)/i;
  
    // Remove apartment or suite numbers from the street address
    const cleanedStreetAddress = streetAddress.replace(aptSuitePattern, '').trim();
  
    // Concatenate the address components
    const concatenatedAddress = [cleanedStreetAddress, city, region, postalCode, countryCode]
      .filter(component => component) // Remove empty components
      .join(', '); // Join components with a comma and space
  
    return concatenatedAddress;
  }

  isWithinLausdBoundingBox(latitude: number, longitude: number): boolean {
    const minLatitude = 33.673677;
    const maxLatitude = 34.823302;
    const minLongitude = -118.707074;
    const maxLongitude = -117.646374;

    console.log(`Lat: ${latitude}`, `Lng: ${longitude}`);
    console.log({ minLatitude, maxLatitude, minLongitude, maxLongitude });

    console.log(
      'is within LAUSD boundary:',
      latitude >= minLatitude &&
        latitude <= maxLatitude &&
        longitude >= minLongitude &&
        longitude <= maxLongitude
    );
    return (
      latitude >= minLatitude &&
      latitude <= maxLatitude &&
      longitude >= minLongitude &&
      longitude <= maxLongitude
    );
  }

  updateGoogleMapsScript(lang: string) {
    const existingScript = document.getElementById(
      'google-maps-script'
    ) as HTMLScriptElement;
    if (existingScript && existingScript.parentNode) {
      existingScript.parentNode.removeChild(existingScript);
    }

    const googleMapsScript = document.createElement('script');
    googleMapsScript.id = 'google-maps-script';
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAVcvISVwUetJBAw5ICs-2WRm31OEga0UI&libraries=places&callback=testMap&language=${
      lang ? lang : 'en'
    }`;
    googleMapsScript.async = true;
    googleMapsScript.defer = true;

    // Append the new script to the body
    document.head.appendChild(googleMapsScript);
  }
}
