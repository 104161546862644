import { SstCmsProviderService } from 'src/app/shared/services/sst-cms-provider.service';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { SstStateService } from 'src/app/shared/services/sst-state.service';
import { SstSessionLogService } from './shared/services/sst-session-log.service';
import { SstService } from './shared/api/facilities/api/sst.service';
import {
  Component,
  HostBinding,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';

import { SettingsService } from './core/settings/settings.service';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { AppConfigService } from './shared/services/app-config.service';
import {
  AppConfigClient,
  CreateApplicationCommand,
  GetConfigurationProfileCommand,
} from '@aws-sdk/client-appconfig';
import { TranslatorService } from './shared/services/translator.service';
import { RoleService } from './routes/admin/services/role.service';
import { Subscription } from 'rxjs';
import { SstCmsService } from './shared/api/facilities';
import { TourService } from './shared/services/tour.service';
import { ProfileService } from './shared/services/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class.layout-fixed') get isFixed() {
    return this.settings.getLayoutSetting('isFixed');
  }
  @HostBinding('class.aside-collapsed') get isCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }
  @HostBinding('class.layout-boxed') get isBoxed() {
    return this.settings.getLayoutSetting('isBoxed');
  }
  @HostBinding('class.layout-fs') get useFullLayout() {
    return this.settings.getLayoutSetting('useFullLayout');
  }
  @HostBinding('class.hidden-footer') get hiddenFooter() {
    return this.settings.getLayoutSetting('hiddenFooter');
  }
  @HostBinding('class.layout-h') get horizontal() {
    return this.settings.getLayoutSetting('horizontal');
  }
  @HostBinding('class.aside-float') get isFloat() {
    return this.settings.getLayoutSetting('isFloat');
  }
  @HostBinding('class.offsidebar-open') get offsidebarOpen() {
    return this.settings.getLayoutSetting('offsidebarOpen');
  }
  @HostBinding('class.aside-toggled') get asideToggled() {
    return this.settings.getLayoutSetting('asideToggled');
  }
  @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }
  _router!: Subscription;

  constructor(
    public settings: SettingsService,
    private sstLog: SstSessionLogService,
    private oauthService: OAuthService,
    private appConfig: AppConfigService,
    private sstStateService: SstStateService,
    private sstCms: SstCmsProviderService,
    private router: Router,
    private translator: TranslatorService,
    private roleService: RoleService,
    private sstCmsService: SstCmsService,
    private currentRoute: ActivatedRoute,
    private tourService: TourService,
    private profileService: ProfileService
  ) {}
  ngOnDestroy(): void {
    //this._roles.unsubscribe()
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.sstCms.s3BaseUrl =
      this.appConfig.appConfig?.environment['sstMediaFolder'];
    // prevent empty links to reload the page
    document.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      if (
        target.tagName === 'A' &&
        ['', '#'].indexOf(target.getAttribute('href') || '') > -1
      )
        e.preventDefault();
    });
    this.sstLog.init();
    this.authCheck();
    this.sstCmsService.v1SstCmsToolsGet().subscribe((tools) => {
      var description = tools.publishedDescription?.description;
      var bgImage = tools.publishedImage?.url;
      this.sstCms.homeDescription = description!;
      this.sstCms.homeBg = bgImage!;
      let isPreview = this.currentRoute.snapshot.queryParams['preview'];
      if (isPreview) {
        let localDescription =
          window.localStorage.getItem('previewDescription');
        this.sstCms.homeDescription = localDescription;
        let localBg = window.localStorage.getItem('previewBg');
        this.sstCms.homeBg = localBg!;
      }
    });
  }

  authCheck() {
    this._router = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        var routeInfo = evt;
        var userProfile = this.oauthService.getIdentityClaims();
        var isNotCmsRole =
          userProfile == null ? false : userProfile['userType'] == 'Parent';
        if (routeInfo.url.startsWith('/admin') && isNotCmsRole)
          window.location.href = '/';
        if (isNotCmsRole) this.roleService.isParent = true;
      }
    });
    this.oauthService.configure(this.authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then((response) => {
      if (this.oauthService.hasValidAccessToken()) {
        this.oauthService.loadUserProfile().then((user: any) => {
          this.sstStateService.actionLogin(user.info.displayName);
          this.sstStateService.isLogged$.next(true);
          //this.router.navigateByUrl('/home');
        });
      }
    });
    // this.oauthService.events.subscribe((event)=>{
    //   if(event.type == 'token_received'){
    //     this.oauthService.loadUserProfile().then((user: any)=>{
    //       this.sstStateService.actionLogin(user.info.displayName)
    //       this.router.navigateByUrl('/home')
    //     })
    //   }
    // })
  }
  authCodeFlowConfig: AuthConfig = {
    issuer:
      this.appConfig.appConfig?.environment.endPoints.authenticationServices,
    skipIssuerCheck: true,
    redirectUri: `${window.location.origin}/auth/token-callback`,
    customTokenParameters: ['usernameNo'],
    postLogoutRedirectUri: `${window.location.origin}`,
    clientId: 'sst_client',
    showDebugInformation: true,
    tokenEndpoint: `${this.appConfig.appConfig?.environment.endPoints.authenticationServices}/connect/token`,
    strictDiscoveryDocumentValidation: false,
    responseType: 'code',
    scope: 'openid parent offline_access IdentityServerApi',
    useSilentRefresh: true,
    dummyClientSecret: '',
    logoutUrl: '',
    customQueryParams: {
      role: 'parents,employees',
      lng: 'en',
      PostLogOutUri: `${window.location.origin}`,
    },
  };
}
